import React from "react";
import BlogPostBanner from "../../components/global/blogpost/BlogPostBanner";
import BlogPostBody from "../../components/global/blogpost/BlogPostBody";
import GetInTouch from "../../components/global/forms/GetInTouch";

const BlogPost = props => {
  const { entry } = props;
  const data = entry?.getIn(["data"])?.toJS();

  if (data) {
    return (
      <>
        <BlogPostBanner {...data} />
        <BlogPostBody {...data} />
        <GetInTouch {...data?.getInTouch} />
      </>
    );
  }

  return <div>Loading...</div>;
};

export default BlogPost;
